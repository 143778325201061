import { getReportSelector, getSchoolYearsSelector } from 'store/selectors/report.selectors';
import PersonalLetter from 'pages/Report/__partials/PersonalLetter';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getReportAction, getSchoolYearsAction } from 'store/actions/report.actions';
import ReportForm from 'pages/Report/__partials/ReportForm';
import Practice from 'pages/Report/__partials/Practice';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/Breadcrumb';
import { useLocation, useParams } from 'react-router-dom';
import { Text, Title } from 'UI/Typography';
import Button from 'UI/Button';
import Tabs from 'UI/Tabs';
import styles from './styles.module.css';
import { Col, Row, Switch } from 'antd';
import DownloadIcon from '../../UI/Icons/DownloadIcon';
import DownloadReportModal from 'pages/Report/__partials/DownloadReportModal';
import SavedIcon from '../../UI/Icons/SavedIcon';
import SavingIcon from '../../UI/Icons/SavingIcon';
import OfflineIcon from '../../UI/Icons/OfflineIcon';
import Select from '../../UI/Select';
import StarsIcon from '../../UI/Icons/Stars';
import { getUserRole } from 'store/selectors/users.selectors';
import cc from 'classcat';

function isDateBetweenMarchAndAugust() {
  // Get the current date
  const currentDate = new Date();

  // Get the current month (0 = January, 1 = February, ..., 11 = December)
  const currentMonth = currentDate.getMonth();

  // Check if the month is between March (2) and August (7)
  return currentMonth >= 2 && currentMonth <= 7;
}

const reportTabItems: { [key: string]: string } = {
  report: 'IzEL',
  personal: 'Persönlicher Brief',
  practice: 'Praktikum',
};

const items = (
  managePracticeVisible: boolean,
  toggleManagePractice: (arg1: boolean) => void,
  handleChangeEvaluationLoading: (arg1: boolean) => void,
  evaluationLoading: boolean,
  compareView: boolean,
  currentPage: number,
  setCurrentPage: (arg1: number) => void,
  reportState: 'active' | 'deactivated' | 'not_active',
  activeReportId: number,
) => [
  {
    label: <span style={{ padding: '0 40px' }}>IzEL</span>,
    key: 'report',
    children: (
      <ReportForm
        evaluationLoading={evaluationLoading}
        reportState={reportState}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleChangeEvaluationLoading={handleChangeEvaluationLoading}
        compareView={compareView}
        activeReportId={activeReportId}
      />
    ),
  },
  {
    label: <span style={{ padding: '0 40px' }}>Persönlicher Brief</span>,
    key: 'personal',
    children: (
      <PersonalLetter
        handleChangeEvaluationLoading={handleChangeEvaluationLoading}
        reportState={reportState}
        activeReportId={activeReportId}
      />
    ),
  },
  {
    label: <span style={{ padding: '0 40px' }}>Praktikum</span>,
    key: 'practice',
    children: (
      <Practice
        managePracticeVisible={managePracticeVisible}
        toggleManagePractice={toggleManagePractice}
        activeReportId={activeReportId}
      />
    ),
  },
];

const Report = () => {
  const [selectedTab, setSelectedTab] = useState('report');
  const [managePracticeVisible, setManagePracticeVisible] = useState(false);
  const [downloadReportVisible, setDownloadReportVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [compareView, setCompareView] = useState(false);
  const [reportState, setReportState] = useState<'active' | 'deactivated' | 'not_active'>('active');
  const [activeReportId, setActiveReportId] = useState<number | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const myElementRef = useRef<null>(null);
  const report = useSelector(getReportSelector);
  const schoolYears = useSelector(getSchoolYearsSelector);
  const { studentId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const userRole = useSelector(getUserRole);

  const schoolYearSelectData = useMemo(() => {
    return schoolYears.map((val) => {
      return {
        value: val.izelId,
        label: `${val.izelHalfYear ? 'Halbjahres-IzEL' : 'Schuljahres-IzEL'} ${
          val.schoolYearName
        } (${
          val?.izelNotActivated ? 'inaktiviert' : val?.izelActive ? 'aktiviert' : 'deaktiviert'
        }) ${val.grade}. Jahrgangsstufe`,
      };
    });
  }, [schoolYears]);

  const defaultReport: any = useMemo(() => {
    return (
      (schoolYears.find((year) => year.schoolYearActive && year.izelHalfYear && year.izelActive) &&
        !isDateBetweenMarchAndAugust()) ||
      schoolYears.find((year) => year.schoolYearActive)
    );
  }, [schoolYears]);

  const selectedSchoolYear = useMemo(() => {
    return schoolYears.find((year) => year.izelId === activeReportId);
  }, [activeReportId, schoolYears]);

  const reportName = useMemo(() => {
    return `${selectedSchoolYear?.izelHalfYear ? 'Halbjahres-IzEL' : 'Schuljahres-IzEL'}_${
      report.surname
    }_${report.name}_${selectedSchoolYear?.grade}_${selectedSchoolYear?.schoolYearName}`;
  }, [
    report.name,
    report.surname,
    selectedSchoolYear?.grade,
    selectedSchoolYear?.izelHalfYear,
    selectedSchoolYear?.schoolYearName,
  ]);

  const toggleManagePractice = useCallback((value: boolean) => {
    setManagePracticeVisible(value);
  }, []);

  const toggleDownloadReport = useCallback((value: boolean) => {
    setDownloadReportVisible(value);
  }, []);

  const handleChangeEvaluationLoading = useCallback((value: boolean) => {
    setEvaluationLoading(value);
  }, []);

  const handleSelectYear = useCallback(
    (value: number) => {
      const selectedReport = schoolYears.find((report) => report.izelId === value);
      const actualState = selectedReport?.izelNotActivated
        ? 'not_active'
        : selectedReport?.izelActive
        ? 'active'
        : 'deactivated';
      setReportState(actualState);
      dispatch(
        getReportAction({
          id: Number(studentId),
          page: 0,
          size: 10,
          izelId: value,
        }),
      );
    },
    [dispatch, schoolYears, studentId],
  );

  const handleScrollPosition = useCallback(() => {
    if (myElementRef.current) {
      const elTop: { scrollTop: number } = myElementRef.current;
      if (
        (elTop.scrollTop === 0 && scrollPosition !== 0) ||
        (elTop.scrollTop !== 0 && scrollPosition === 0)
      ) {
        setScrollPosition(elTop.scrollTop);
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    dispatch(
      getReportAction({
        id: Number(studentId),
        page: currentPage - 1,
        size: 10,
        izelId: Number(activeReportId),
      }),
    );
  }, [activeReportId, currentPage, dispatch, studentId]);

  useEffect(() => {
    dispatch(getSchoolYearsAction({ id: Number(studentId) }));
  }, [dispatch, studentId]);

  useEffect(() => {
    setActiveReportId(defaultReport?.izelId || null);
    const actualState = defaultReport?.izelNotActivated
      ? 'not_active'
      : defaultReport?.izelActive
      ? 'active'
      : 'deactivated';
    setReportState(actualState);
  }, [defaultReport]);

  return (
    <div className={styles.reportContainer}>
      <div
        className={cc([
          styles.scrollViewWrapper,
          {
            [styles.wrapperScrolled]: scrollPosition > 0,
          },
        ])}
      >
        <div className={styles.breadcrumbWrapper}>
          <Breadcrumb
            items={[
              { title: 'Schüler/Schülerinnen', url: `/students${location.search}` },
              { title: reportTabItems[selectedTab], url: '' },
            ]}
          />
          {!navigator.onLine ? (
            <div className={styles.statusWrapper}>
              <OfflineIcon /> <Text level={1}>Daten nicht gespeichert (kein Internet?)</Text>
            </div>
          ) : evaluationLoading ? (
            <div className={styles.statusWrapper}>
              <SavingIcon /> <Text level={1}>Änderungen speichern</Text>
            </div>
          ) : (
            <div className={styles.statusWrapper}>
              <SavedIcon /> <Text level={1}>Alle Änderungen sind gespeichert</Text>
            </div>
          )}
        </div>
        <Row justify='space-between' align='middle'>
          <Col>
            <div className={styles.titleWrapper}>
              <Title
                level={1}
              >{`IzEL für ${report.surname}, ${report.name} ${report.schoolYear}. Jahrgangsstufe `}</Title>
            </div>
            <div className={styles.subTitleWrapper}>
              <Select
                style={{ minWidth: '150px' }}
                value={activeReportId}
                showSearch={false}
                onChange={(value) => {
                  handleSelectYear(value);
                  setActiveReportId(value);
                  setCurrentPage(1);
                }}
                options={schoolYearSelectData}
                showArrow
              />
            </div>
          </Col>
          <Col style={{ marginTop: '12px', marginLeft: '24px' }}>
            <Button
              disabled={reportState === 'not_active'}
              onClick={() => toggleDownloadReport(true)}
              type='default'
              icon={<DownloadIcon />}
              style={{ fontWeight: 'bold' }}
              className='primary-40'
            >
              IzEL erstellen
            </Button>
          </Col>
        </Row>
      </div>
      <div className={styles.descWrapper} onScroll={handleScrollPosition} ref={myElementRef}>
        {reportState === 'not_active' ? (
          <div>
            <div className={styles.iconPosition}>
              <StarsIcon />
            </div>
            <div className={styles.placeHolderWrapper}>
              <Title level={3} className={styles.placeHolderText}>
                <span>
                  IzEL für das Schuljahr {selectedSchoolYear?.schoolYearName} wurden noch nicht{' '}
                  <br /> von der Schulbverwaltung / -leitung feigegeben. <br /> Sie können aber in
                  der Auswahlliste oben auf ältere IzEL zurückgreifen. <br /> Falls Sie Fragen
                  haben, wenden Sie sich bitte an die Schulverwaltung/-leitung.
                </span>
              </Title>
            </div>
          </div>
        ) : (
          <Tabs
            defaultActiveKey='1'
            items={items(
              managePracticeVisible,
              toggleManagePractice,
              handleChangeEvaluationLoading,
              evaluationLoading,
              compareView,
              currentPage,
              setCurrentPage,
              reportState,
              Number(activeReportId),
            )}
            onChange={(key: string) => setSelectedTab(key)}
            tabBarExtraContent={
              selectedTab === 'report' && userRole === 'TEACHER' ? (
                <span style={{ display: 'flex', gap: '16px' }}>
                  <Text level={1}>nicht bewertete Inhalte</Text>
                  <Switch checked={compareView} onChange={(checked) => setCompareView(checked)} />
                  <Text level={1} bold>
                    Selbstbewertung
                  </Text>
                </span>
              ) : selectedTab === 'practice' ? (
                <Button
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => toggleManagePractice(true)}
                >
                  Neues Praktikum hinzufügen
                </Button>
              ) : null
            }
          />
        )}
      </div>
      <DownloadReportModal
        reportName={reportName}
        reportDisabled={report.hasAnyEvaluations || report.hasAnyTexts}
        personalLetterEnabled={!report.hasPersonalLetter}
        downloadReportVisible={downloadReportVisible}
        toggleDownloadReport={toggleDownloadReport}
        activeReportId={activeReportId}
      />
    </div>
  );
};

export default Report;
