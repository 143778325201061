import { Modal as AntdModal, ModalProps } from 'antd';
import cc from 'classcat';
import styles from './styles.module.css';
import { Title } from '../Typography';
import AttentionIcon from '../Icons/AttentionIcon';

const Modal = ({
  className,
  okDisabled,
  title,
  fixScroll,
  ...props
}: ModalProps & { title: string; okDisabled?: boolean; fixScroll?: boolean }) => {
  return (
    <AntdModal
      maskClosable={false}
      title={<Title level={5}>{title}</Title>}
      className={cc([className, styles.modal, { [styles.fixedScroll]: fixScroll }])}
      okButtonProps={{ className: styles.okButton, disabled: okDisabled }}
      cancelButtonProps={{ className: styles.cancelButton }}
      {...props}
    />
  );
};

const Confirm = ({ className, message, ...props }: ModalProps & { message: string }) => {
  return (
    <AntdModal
      maskClosable={false}
      className={cc([className, styles.modal, styles.confirm])}
      okButtonProps={{ className: styles.okButton }}
      cancelButtonProps={{ className: styles.cancelButton }}
      {...props}
    >
      <div className={styles.confirmIconWrapper}>
        <div className={styles.confirmIcon}>
          <AttentionIcon />
        </div>
        <Title level={5}>{message}</Title>
      </div>
    </AntdModal>
  );
};

Modal.Confirm = Confirm;

export default Modal;
