import Modal from '../../../../UI/Modal';
import { groupTypes, removeGroupAction } from 'store/actions/group.actions';
import { RadioChangeEvent } from 'antd';
import RadioGroup from '../../../../UI/RadioGroup';
import React, { useCallback, useState } from 'react';
import AttentionIcon from '../../../../UI/Icons/AttentionIcon';
import { useDispatch } from 'react-redux';

type RemoveGroupModalTypes = {
  removeGroupVisible: boolean;
  toggleRemoveGroup: (arg1: boolean) => void;
  selectedGroup: groupTypes | undefined;
  showOnlyTeachers: boolean;
  currentPage: number;
  handleSelectGroup: (arg1: number | null) => void;
};

const options = [
  {
    key: 1,
    label:
      'Die Zuordungen der fachlichen Inhalte dieser Gruppe werden für alle Schüler der Gruppe entfernt',
  },
  {
    key: 2,
    label:
      'Die Zuweisungen zu fachlichen Inhalten, die sich aus der Gruppe ergeben haben, bleiben auch nach Löschen der Gruppe erhalten.',
  },
];

const RemoveGroupModal = ({
  removeGroupVisible,
  toggleRemoveGroup,
  selectedGroup,
  showOnlyTeachers,
  currentPage,
  handleSelectGroup,
}: RemoveGroupModalTypes) => {
  const [keepAssignments, setKeepAssignments] = useState(1);
  const dispatch = useDispatch();

  const handleSelectOption = useCallback((value: number) => {
    setKeepAssignments(value);
  }, []);

  const handleResetModal = useCallback(() => {
    toggleRemoveGroup(false);
    handleSelectGroup(null);
    handleSelectOption(1);
  }, [handleSelectGroup, handleSelectOption, toggleRemoveGroup]);

  const handleRemoveGroup = useCallback(() => {
    dispatch(
      removeGroupAction({
        showTeachers: showOnlyTeachers,
        id: Number(selectedGroup?.id),
        page: currentPage - 1,
        keepAssignments: keepAssignments === 2,
        onSuccess: () => handleResetModal(),
      }),
    );
  }, [
    currentPage,
    dispatch,
    handleResetModal,
    keepAssignments,
    selectedGroup?.id,
    showOnlyTeachers,
  ]);

  return (
    <Modal
      title='Gruppe Löschen'
      open={removeGroupVisible}
      onCancel={handleResetModal}
      centered
      closable
      okText='Löschen'
      cancelText='Abbrechen'
      onOk={handleRemoveGroup}
    >
      <div style={{ display: 'flex', gap: '8px' }}>
        <div>
          <AttentionIcon />
        </div>
        <div>
          {!selectedGroup?.studentsNumber ? (
            <div
              style={{ fontWeight: 700 }}
            >{`Sind Sie sich sicher, dass Sie die Gruppe ${selectedGroup?.name} löschen wollen?`}</div>
          ) : (
            <>
              <div style={{ fontWeight: 700 }}>
                {`Der Gruppe ${selectedGroup?.name} sind ${selectedGroup?.studentsNumber} Schüler/innen
          zugewiesen.`}
              </div>
              <div style={{ margin: '8px 0', fontWeight: 700 }}>
                {`Bitte legen Sie fest, wie mit den Zuweisungen fachicher Inhalte für die Schüler in der Gruppe ${selectedGroup?.name} verfahren werden soll.`}
              </div>
              <div>
                <RadioGroup
                  defaultValue={1}
                  value={keepAssignments}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexFlow: 'column nowrap',
                  }}
                  groupOptions={options}
                  onChange={(e: RadioChangeEvent) => handleSelectOption(e.target.value)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RemoveGroupModal;
