import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createItemService,
  createSubItemService,
  editItemService,
  editSubItemService,
  getAssessmentScalesService,
  getItemsService,
  removeItemService,
  reorderSubItemsService,
} from 'store/services/items.services';
import { ActionNames } from 'store/actions/actionNames';
import {
  createItemParaTypes,
  getItemParamTypes,
  getItemsActionSuccess,
  itemTypes,
  itemsResponseType,
  createSubItemParaTypes,
  subItemTypes,
  createItemActionSuccess,
  removeItemParamTypes,
  removeItemActionSuccess,
  removeSubItemActionSuccess,
  editItemParamTypes,
  editItemActionSuccess,
  editSubItemParamTypes,
  createSubItemActionSuccess,
  editSubItemActionSuccess,
  getAssessmentScalesActionSuccess,
  assessmentScalesTypes,
  reorderSubItemActionParamTypes,
  getItemsAction,
} from 'store/actions/items.actions';

function* watchGetAssessmentScales() {
  try {
    const response: assessmentScalesTypes = yield call(...getAssessmentScalesService());
    yield put(getAssessmentScalesActionSuccess(response));
  } catch (e) {
    console.log('Can not get assessment scales');
  }
}

function* watchGetItems(action: { params: getItemParamTypes; type: string }) {
  try {
    const response: itemsResponseType = yield call(...getItemsService(action.params));
    yield put(getItemsActionSuccess(response));
  } catch (e) {
    console.log('Can not get items');
  }
}

function* watchCreateItem(action: { params: createItemParaTypes; type: string }) {
  try {
    yield call(...createItemService(action.params));
    yield put(
      getItemsAction({
        subjectId: action.params.subjectId,
        page: Number(action.params.currentPage),
        size: 10,
      }),
    );
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.name));
    console.log('Can not create item');
  }
}

function* watchEditItem(action: { params: editItemParamTypes; type: string }) {
  try {
    const response: itemTypes = yield call(...editItemService(action.params));
    yield put(editItemActionSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.name));
    console.log('Can not edit item');
  }
}

function* watchCreateSubItem(action: { params: createSubItemParaTypes; type: string }) {
  try {
    const response: subItemTypes = yield call(...createSubItemService(action.params));
    yield put(createSubItemActionSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.name));
    console.log('Can not create sub item');
  }
}

function* watchEditSubItem(action: { params: editSubItemParamTypes; type: string }) {
  try {
    const response: subItemTypes = yield call(...editSubItemService(action.params));
    yield put(editSubItemActionSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.name));
    console.log('Can not edit sub item');
  }
}

function* watchRemoveItem(action: { params: removeItemParamTypes; type: string }) {
  try {
    yield call(...removeItemService(action.params));
    if (action.params.parentId) {
      yield put(
        removeSubItemActionSuccess({ id: action.params.id, parentId: action.params.parentId }),
      );
    } else {
      yield put(removeItemActionSuccess({ id: action.params.id }));
    }
  } catch (e) {
    console.log('Can not delete item/sub-item');
  }
}

function* watchReorderSubItems(action: { params: reorderSubItemActionParamTypes; type: string }) {
  try {
    yield call(...reorderSubItemsService(action.params));
  } catch (e) {
    console.log('Can not delete item/sub-item');
  }
}

function* itemsSaga() {
  yield all([
    takeLatest(ActionNames.GET_ASSESSMENT_SCALES, watchGetAssessmentScales),
    takeLatest(ActionNames.GET_ITEMS, watchGetItems),
    takeLatest(ActionNames.CREATE_ITEM, watchCreateItem),
    takeLatest(ActionNames.EDIT_ITEM, watchEditItem),
    takeLatest(ActionNames.CREATE_SUB_ITEM, watchCreateSubItem),
    takeLatest(ActionNames.EDIT_SUB_ITEM, watchEditSubItem),
    takeLatest(ActionNames.REMOVE_ITEM, watchRemoveItem),
    takeLatest(ActionNames.REORDER_SUB_ITEMS, watchReorderSubItems),
  ]);
}

export default itemsSaga;
