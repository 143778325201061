import { ActionNames } from 'store/actions/actionNames';
import { subjectTypes } from 'actions/subjects.actions';

export type subItemTypes = {
  id: number;
  name: string;
  subjectId: number;
  assessmentScaleId: number;
  parentId: number;
  optional: boolean;
  schoolYears: Array<string>;
  isAssociation: boolean;
  description: string;
};

export type itemTypes = {
  id: number;
  subjectId: number;
  assessmentScaleId: number;
  parentId: number | null;
  name: string;
  alias: string;
  optional: boolean;
  schoolYears: Array<string>;
  assessmentScale: { name: 'Quantitative' | 'Qualtaive' | 'List'; id: number };
  subItems: Array<subItemTypes>;
  isAssociation: boolean;
};

export type itemsResponseType = {
  count: number;
  items: Array<itemTypes>;
  subject: subjectTypes;
};

export type getItemParamTypes = {
  subjectId: number;
  page: number;
  size: number;
};

export type createItemParaTypes = {
  name: string;
  optional: boolean;
  subjectId: number;
  assessmentScaleId: number;
  alias: string;
  onSuccess: () => void;
  onFailure: (arg1: string) => void;
  currentPage: number | undefined;
};

export type editItemParamTypes = {
  id: number;
  name: string;
  optional: boolean;
  assessmentScaleId: number;
  alias: string;
  onSuccess?: () => void;
  onFailure: (arg1: string) => void;
};

export type createSubItemParaTypes = {
  name: string;
  optional: boolean;
  schoolYears: Array<number>;
  parentId: number;
  onSuccess: () => void;
  onFailure: (arg1: string) => void;
  description: string;
};

export type editSubItemParamTypes = {
  id: number;
  name: string;
  optional: boolean;
  schoolYears: Array<string>;
  parentId: number;
  description: string;
  onSuccess?: () => void;
  onFailure: (arg1: string) => void;
};

export type removeItemParamTypes = {
  id: number;
  parentId?: number;
};

export type removeSubItemParamTypes = {
  id: number;
  parentId: number;
};

export type assessmentScalesTypes = Array<{ name: string; id: number }>;

export type reorderSubItemActionParamTypes = {
  itemId: number;
  previousSubItemId: number | null;
  nextSubItemId: number | null;
  subItemId: number;
};

export const getAssessmentScalesAction = { type: ActionNames.GET_ASSESSMENT_SCALES };

export const getAssessmentScalesActionSuccess = (assessmentScales: assessmentScalesTypes) => ({
  type: ActionNames.GET_ASSESSMENT_SCALES_SUCCESS,
  payload: assessmentScales,
});

export const getItemsAction = (params: getItemParamTypes) => ({
  type: ActionNames.GET_ITEMS,
  params,
});

export const getItemsActionSuccess = (items: itemsResponseType) => ({
  type: ActionNames.GET_ITEMS_SUCCESS,
  payload: items,
});

export const createItemAction = (params: createItemParaTypes) => ({
  type: ActionNames.CREATE_ITEM,
  params,
});

export const createItemActionSuccess = (item: itemTypes) => ({
  type: ActionNames.CREATE_ITEM_SUCCESS,
  payload: item,
});

export const editItemAction = (params: editItemParamTypes) => ({
  type: ActionNames.EDIT_ITEM,
  params,
});

export const editItemActionSuccess = (item: itemTypes) => ({
  type: ActionNames.EDIT_ITEM_SUCCESS,
  payload: item,
});

export const createSubItemAction = (params: createSubItemParaTypes) => ({
  type: ActionNames.CREATE_SUB_ITEM,
  params,
});

export const createSubItemActionSuccess = (subItem: subItemTypes) => ({
  type: ActionNames.CREATE_SUB_ITEM_SUCCESS,
  payload: subItem,
});

export const editSubItemAction = (params: editSubItemParamTypes) => ({
  type: ActionNames.EDIT_SUB_ITEM,
  params,
});

export const editSubItemActionSuccess = (subItem: subItemTypes) => ({
  type: ActionNames.EDIT_SUB_ITEM_SUCCESS,
  payload: subItem,
});

export const removeItemAction = (params: removeItemParamTypes) => ({
  type: ActionNames.REMOVE_ITEM,
  params,
});

export const removeItemActionSuccess = (params: removeItemParamTypes) => ({
  type: ActionNames.REMOVE_ITEM_SUCCESS,
  payload: params,
});

export const removeSubItemActionSuccess = (params: removeSubItemParamTypes) => ({
  type: ActionNames.REMOVE_SUB_ITEM_SUCCESS,
  payload: params,
});

export const reorderSubItemsAction = (params: reorderSubItemActionParamTypes) => ({
  type: ActionNames.REORDER_SUB_ITEMS,
  params,
});
